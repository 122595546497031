.floor-select {
  background-color: $green;
  width: 207px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 14px;
    opacity: 1;
    color: $white;
    margin-bottom: 30px;
  }

  .floor-list {

    li {
      font-size: 18px;
      line-height: em(26px, 18px);
      color: $white;
      opacity: .5;
      text-align: center;
      margin-bottom: 20px;
      font-weight: 500;
      position: relative;
      cursor: pointer;
      z-index: 1;
      transition: .3s ease-in-out;

      &::after {
        position: absolute;
        content: "";
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 2px solid transparent;
        top: -9px;
        left: 8px;
        z-index: -1;
        transition: .3s ease-in-out;
      }

      &:hover {
        opacity: 1;
        color: $green;

        &::after {
          background-color: $white;
        }
      }

      &.act {
        opacity: 1;

        &::after {
          border-color: $white;
        }
      }
    }
  }
}

.floor {
  width: 100%;

  &__map {
    margin: 0 auto;
    @include bgContain();
    width: 620px;
    height: 350px;
  }

  polygon, rect {
    fill: $green;
    opacity: 0;
    transition: .2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: .5;
    }

    &.taken {
      fill: $pink;
      opacity: .5;
    }
  }
}
