.filters {
  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $green;
  }

  .ui-slider .ui-slider-range {
    background-color: $green;
    border: none;
    height: 2px;
  }

  .ui-widget.ui-widget-content {
    border: none;
  }

  .ui-slider-horizontal {
    max-width: 200px;
    height: 1px;
    border: none;
    background-color: $border-grey;

    @media screen and (max-width: $tab) {
      max-width: 100%;
      width: 100%;
    }
  }

  .ui-slider-horizontal .ui-slider-handle {
    background-color: $white;
    top: -11px;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    

    &:hover, &.ui-state-active {
      background-color: $green;
    }
  }

  .value-holder {
    position: relative;
    height: 100%;

    &::after {
      content: attr(data-val);
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      font-family: $font;
      font-size: 12px;
      color: $green;
      font-weight: 500;
    }
  }

  .switches {

    &__switch {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media screen and (max-width: $tab) {
        justify-content: center;
      }

      p {
        opacity: 1;
      }

      input {
        display: none;
      }

      label {
        position: relative;
        width: 42px;
        height: 24px;
        margin-right: 10px;
        color: $green;
        cursor: pointer;

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 12px;
          background-color: rgba($color: $dark, $alpha: .2);
          display: block;
          transition: background-color .1s ease-in-out;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 2px;
          width: 20px;
          height: 20px;
          background-color: $white;
          border-radius: 50%;
          transition: .15s ease-in-out;
          transform: translateX(2px);
        }
      }

      input:checked ~ label {

        &::before {
          background-color: currentColor;
        }

        &::after {
          transform: translateX(20px);
        }
      }
    }
  }
}
