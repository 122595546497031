.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: transparent;
  transition: background-color .3s ease-in-out;

  @media screen and (max-width: $tab) {
    height: 70px;
    border-bottom: 1px solid $border-grey;
    background-color: $light;
  }

  &-container {
    display: flex;
    max-width: $lg;
    margin: 0 auto;
    justify-content: space-between;
    padding: 48px 50px;
    transition: padding .3s ease-in-out;
  }

  &-r-block {
    position: absolute;
    right: 0px;
    top: 0px;

    @media screen and (max-width: $tab) {
      right: auto;
      left: 0;
      z-index: 9;
    }

    .logo {
      width: 207px;
      height: 200px;
      background-color: $dark;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: height .3s ease-in-out;

      @media screen and (max-width: $tab) {
        height: 70px;
        width: auto;
        background-color: transparent;
      }

      svg {
        transition: height .3s ease-in-out;

        @media screen and (max-width: $tab) {
          height: 16px;
        }

        path {
          transition: fill .3s ease-in-out;
          @media screen and (max-width: $tab) {
            fill: $dark;
          }
        }
      }
    }
  }

  &.mob-opened {
    
    .logo {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &-menu {

    &__item {
      display: inline-block;
      margin-right: 40px;
      position: relative;

      a {
        font-size: 14px;
        color: $black;
        font-weight: 500;
        transition: .3s ease-in-out;
      }

      &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 0;
        background-color: $green;
        left: 0;
        bottom: -12px;
        transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }

      &:hover, &.act {
        
        a {
          color: $green;
        }

        &::after {
          width: 50%;
        }
      }
    }
  }

  &.shrink {
    background-color: $white;

    .header-container {
      padding: 33px 50px;
    }

    .logo {
      height: 80px;

      svg {
        height: 24px;
      }
    }
  }

  .burger {
    height: 100%;
    width: 84px;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid $border-grey;

    .lines {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .line {
        width: 24px;
        height: 2px;
        background-color: $dark;
        margin: 0 10px;

        &:first-child {
          margin-bottom: 6px;
        }
      }
    }
  }

  .mobile-menu {
    background-color: $green;
    position: fixed;
    top: 0px;
    right: -100%;
    height: 100vh;
    width: 100vw;
    padding: 24px 0 30px 30px;
    transition: right .5s cubic-bezier(0.175, 0.885, 0.32, 1.1);

    &__close {
      position: absolute;
      top: 24px;
      right: 30px;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: em(.2px, 14px);
      display: flex;
      align-items: center;
      z-index: 2;

      &-cross {
        margin-left: 15px;
      }
    }

    &__items {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      // overflow-y: scroll;
    }

    &__image {
      position: absolute;
      right: 0px;
      width: 50%;
      height: 50vh;
      @include bgCover();
      top: 50%;
      transform: translate(110%, -50%);
      transition: .3s ease-in-out;
      
    }

    .list {
      align-self: center;
      max-width: calc(50% - 30px);
      opacity: 0;
      transform: translateY(50px);
      transition: .3s ease-in-out;
      


      &-item {
        color: $white;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: em(.2px, 16px);
        line-height: em(20px, 16px);

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        a {
          color: inherit;
        }
      }
    }

    &.open {
      right: 0px;

      .mobile-menu__image {
        transform: translate(0%, -50%);
        transition-delay: .5s;
      }

      .list {
        opacity: 1;
        transform: translateY(0px);
        transition-delay: .7s;
      }
    }
  }
}

.login {
  position: absolute;
  right: 0px;
  margin-right: 250px;

  &__item {
    font-size: 14px;
    color: $black;
    font-weight: 500;
    transition: .3s ease-in-out;
    padding-left: 30px;
    position: relative;
    cursor: pointer;

    &.my-profile {
      padding-left: 34px;

      &::after {
        left: 34px;
      }
    }

    .icon {
      position: absolute;
      left: 0px;
      top: -5px;
      width: 20px;
      height: 24px;

      svg {
        height: 24px;
      }

      path {
        transition: .3s ease-in-out;
      }
    }

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 0;
      background-color: $green;
      left: 30px;
      bottom: -10px;
      transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &:hover {
      color: $green;

      .icon {
        path {
          fill: $green;
        }
      }

      &::after {
        width: 50%;
      }
    } 

    &.my-profile {

      .icon {
        width: 24px;
      }
    }
  }

  &--mobile {
    position: relative;
    margin-right: 0;
    position: absolute;
    bottom: 0px;
    left: 0px;

    .login__item {
      color: $white;
      display: inline-block;
      font-size: 16px;
      padding-bottom: 5px;

      .icon {
        top: -10px;

        svg {
          path {
            fill: $white;
          }
        }
      }

      &.my-profile {

        .icon {
          top: -5px;
        }
      }
    }
  }
}
