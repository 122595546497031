.home-hero {
  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .subtitle {
    width: 100%;
    margin-bottom: 40px;

    @media screen and (max-width: $tab) {
      max-width: 65%;
      margin-bottom: 30px;
    }
  }
}

.home-slider {
  &__outer {
    height: 650px;
    position: relative;

    @media screen and (max-width: $tab) {
      height: 450px;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }

    @media screen and (max-width: $sm) {
      height: 280px;
    }
  }

  &__container {
    height: 100%;
  }

  &__wrapper {
    &::after {
      @include animatedOverlay();
      @include animatedOverlayAnim();
    }
  }

  &__slide {
    @include bgCover();
  }

  &__nav {
    width: 240px;
    height: 120px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    display: flex;
    transform: translateX(-50%);
    z-index: 5;

    @media screen and (max-width: $tab) {
      width: 180px;
      height: 90px;
    }

    [aria-disabled="true"] {
      .icon {
        svg,
        path {
          fill: grey;
        }
      }
    }
  }

  &__next,
  &__prev {
    width: 50%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   background: rgb(170,180,181);
    //   background: linear-gradient(180deg, rgba(170,180,181,0.8) 0%, rgba(255,255,255,1) 100%);
    //   z-index: -1;
    // }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg,
      path {
        fill: $green;
      }
    }

    // &:not([aria-disabled="true"]):hover {
    //   // background: rgba($color: $green, $alpha: 0.5);

    //   .icon {
    //     svg,
    //     path {
    //       // fill: $white;
    //       // transition: 0.1s ease-in-out;
    //     }
    //   }
    // }
  }

  &__pagination.swiper-pagination-fraction {
    position: absolute;
    display: inline-block;
    left: auto;
    width: auto;
    bottom: 44px;
    right: -50px;
    z-index: 3;
    transform: rotate(90deg);
    font-weight: 500;
    color: rgba($color: $dark, $alpha: 0.5);

    @media screen and (max-width: $tab) {
      right: 20px;
    }
  }

  .scroll-down {
    position: absolute;
    display: inline-block;
    width: auto;
    right: -50px;
    top: 45px;
    cursor: pointer;

    small {
      writing-mode: vertical-lr;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: em(0.2px, 12px);
      margin-bottom: 20px;
    }

    #mouse {
      height: 24px;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-name: scroll;
    }
  }
}

.slogan {
  background-color: $dark;
  position: relative;

  &-self {
    width: calc(50% - 120px);
    padding: 100px 0 0;

    @media screen and (max-width: $tab) {
      width: 100%;
      padding: 60px 0;
    }

    &__text {
      max-width: 324px;
      color: $light;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: calc(50% + 120px);
    top: 0px;
    right: 0px;
    background-color: $white;
    z-index: 0;

    @media screen and (max-width: $tab) {
      display: none;
    }
  }
}

.benefit {
  width: calc(50% + 120px);
  margin: 0 0 0 auto;
  z-index: 1;

  @media screen and (max-width: $tab) {
    width: calc(100% + 40px);
    background-color: $white;
    margin: 0 -20px 0 -20px;
  }

  &__wrap {
    padding: 40px 0;

    @media screen and (max-width: $tab) {
      padding: 60px 20px;
    }
  }

  &__item {
    padding: 60px 0;
    padding-left: 40%;

    @media screen and (max-width: $tab) {
      padding: 0;
    }

    .text-content {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $border-grey;

      @media screen and (max-width: $tab) {
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
    }

    .icon {
      margin-bottom: 30px;

      svg {
        max-height: 72px;
        path {
          fill: $green;
        }
      }
    }

    .title {
      margin-bottom: 10px;
    }

    .text {
      opacity: 0.5;
    }
  }
}

.about-project-link {
  height: 80px;
  width: 100%;
  position: relative;
  z-index: 1;

  &__wrap {
    width: calc(50% + 120px);
    height: 100%;
    margin: 0 0 0 auto;
    background-color: $green;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out;

    &.hover {
      background-color: $green--hover;
    }

    @media screen and (max-width: $tab) {
      width: 100%;
    }

    & > a {
      color: $white;
      font-size: 18px;
      letter-spacing: em(0.2px, 18px);
      font-weight: 400;

      @media screen and (max-width: $tab) {
        font-size: 16px;
      }

      &:hover {
        .arrow {
          left: 5px;
        }
      }
    }

    .arrow {
      margin-left: 27px;
      position: relative;
      left: 0px;
      transition: left 0.3s ease-in-out;

      @media screen and (max-width: $tab) {
        margin-left: 15px;
      }

      svg,
      path {
        fill: $white;
      }
    }
  }
}

.home-gallery {
  background-color: $light;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 100px;
    width: 100%;
    background-color: $dark;
    top: 0px;
    left: 0px;
    z-index: 0;
  }

  &-main {
    display: flex;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
      margin-bottom: 60px;
    }

    .title-wrap {
      width: calc(50% - 120px);
      padding-left: calc((100vw - 1200px) / 2);
      padding-top: 200px;
      padding-right: 100px;

      @media screen and (max-width: $tab) {
        padding: 0 20px;
        width: 100%;
        order: 2;
      }

      &__title {
        padding-left: 50px;

        @media screen and (max-width: $tab) {
          padding: 0;
        }
      }
    }

    .figure {
      width: calc(50% + 120px);
      position: relative;
      margin: 0 0 60px auto;

      @media screen and (max-width: $tab) {
        order: 1;
        width: 100%;
        margin-bottom: 40px;
      }

      img {
        width: 100%;
      }

      figcaption {
        @media screen and (max-width: $tab) {
          padding-left: 20px;
        }
      }
    }
  }

  &-sec {
    padding-bottom: 100px;

    @media screen and (max-width: $tab) {
      padding-bottom: 60px;
    }

    .gallery-list {
      @include clearfix();

      @media screen and (max-width: $tab) {
        display: flex;
        flex-wrap: wrap;
      }

      &__item {
        max-width: 50%;
        margin-bottom: 62px;

        &:nth-child(odd) {
          float: left;
        }

        &:nth-child(even) {
          float: right;
          margin-left: 100px;
          max-width: calc(50% - 100px);

          @media screen and (max-width: $tab) {
            max-width: 100%;
            padding: 0;
            margin-left: 0;
          }
        }

        &:first-child {
          margin-top: 100px;

          @media screen and (max-width: $tab) {
            margin: 0 0 40px;
          }
        }

        &:nth-child(1n + 0) {
          img {
            @media screen and (min-width: $tab + 1) {
              height: 350px;
            }
          }
        }

        &:nth-child(2n + 0) {
          img {
            @media screen and (min-width: $tab + 1) {
              height: 650px;
            }
          }
        }

        &:nth-child(3n + 0) {
          max-width: 43%;
          margin-left: 7%;

          @media screen and (max-width: $tab) {
            max-width: 100%;
            margin: 0 0 40px;
          }

          img {
            @media screen and (min-width: $tab + 1) {
              height: 350px;
            }
          }
        }

        &:nth-child(4n + 0) {
        }

        @media screen and (max-width: $tab) {
          width: 100%;
          max-width: 100%;
          padding: 0;
          margin: 0 0 40px;
          float: unset;
        }
      }

      .quote {
        margin-bottom: 0px;

        &-text {
          padding-right: 20%;

          @media screen and (max-width: $tab) {
            padding: 0;
          }
        }
      }
    }
  }
}
