.tooltip {
  display: none;
  background-color: $green;
  height: 100px;
  min-width: 260px;
  position: fixed;
  color: $white;
  padding: 0 30px;
  z-index: 100;
  pointer-events: none;

  &__inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__floor, &__room {
    font-size: 62px;
    font-weight: 500;
    opacity: 1;
    padding-right: 24px;
    margin-right: 30px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 60px;
      width: 1px;
      background-color: rgba($color: $light, $alpha: .5);
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__flats {
    opacity: 1;
    font-size: 12px;
    letter-spacing: em(.2px, 12px);
    font-weight: 400;
  }
}
