.interior-tabs {

  &__buttons {
    border-bottom: 1px solid $border-grey;
    margin-bottom: 60px;

    @media screen and (max-width: $tab) {
      margin-bottom: 30px;
    }

    & > .container {

      @media screen and (max-width: $tab) {
        overflow-x: scroll;
        padding-bottom: 2px;
      }
    }
  }

  &__wrap {
    display: flex;

    @media screen and (max-width: $tab) {
      width: 600px;
      margin: 0 auto;
    }

    &--double {
      justify-content: center;

      @media screen and (max-width: $tab) {
        width: 100%;
      }

      .interior-tabs__button {
        width: 50%;
      }
    }
  }

  &__button {
    font-size: 14px;
    color: rgba($color: $dark, $alpha: .5);
    font-weight: 500;
    width: calc(100% / 3);
    text-align: center;
    padding-bottom: 20px;
    position: relative;
    cursor: pointer;
    transition: .4s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: $green;
      bottom: 0px;
      left: 0px;
      transform-origin: center center;
      transform: scaleX(0) translate3d(0,100%,0);
      transition: .4s ease-in-out;
    }

    &:hover, &.act {
      color: $green;

      &::after {
        transform: scaleX(1) translate3d(0,100%,0);
      }
    }
  }

  &-list {

    .tab {
      opacity: 1;
      pointer-events: all;
      z-index: 1;
      visibility: visible;
      transition: opacity .5s ease-in-out;
      position: relative;

      &.hidden {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        visibility: hidden;
        position: absolute;
        height: 0px;
        top: 0px;
        height: 0px;
        overflow: hidden;
      }

      &__item {
        width: 100%;

        .figure-wrap, img {
          width: 100%;
        }
      }

      &-upper {
        @include clearfix();
        margin-bottom: 100px;

        @media screen and (max-width: $tab) {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          flex-direction: column;
          padding: 0 20px;
          margin-bottom: 0;
        }

        .quote-text {
          margin-bottom: 100px;

          &__inner {
            padding-right: calc((100vw - 1100px) / 2);
          }
        }

        .tab__item {

          &:nth-child(odd) {
            float: right;
          }

          &:nth-child(even) {
            float: left;
          }

          &:nth-child(1n + 0) {
            max-width: 50%;
            order: 2;

            @media screen and (max-width: $tab) {
              max-width: 100%;
              width: 100%;
              margin: 0 0 40px;
            }
          }

          &:nth-child(2n + 0) {
            max-width: 47%;
            padding-right: 3%;
            padding-left: calc((100vw - 1100px) / 2);

            @media screen and (max-width: $tab) {
              order: 1;
              width: 100% ;
              margin: 0 0 40px;
              padding: 0;
              max-width: 100%;
            }

            img {

              @media screen and (min-width: $tab + 1) {
                height: 650px;
              }
            }
          }

          &:nth-child(3n + 0) {
            max-width: 50%;
            padding-right: 3%;
            margin-bottom: 62px;

            @media screen and (max-width: $tab) {
              order: 3;
              width: 100% ;
              margin: 0 0 40px;
              padding: 0;
              max-width: 100%;
            }

            img {

              @media screen and (min-width: $tab + 1) {
                height: 550px;
              }
            }
          }

          &:nth-child(4n + 0) {
            max-width: 60%;
            padding-left: 0;
            padding-right: 5%;

            @media screen and (max-width: $tab) {
              order: 3;
              width: calc(100% + 40px);
              margin: 0 -20px 40px -20px;
              padding: 0;
              max-width: calc(100% + 40px);
            }

            figcaption {
              padding-left: 50px;

              @media screen and (max-width: $tab) {
                padding-left: 20px;
              }
            }

            img {

              @media screen and (min-width: $tab + 1) {
                height: 700px;
              }
            }
          }

          &:nth-child(5n + 0) {
            max-width: 40%;
            padding-right: 10%;
            padding-top: 126px;

            @media screen and (max-width: $tab) {
              order: 3;
              width: 100% ;
              margin: 0 0 40px;
              padding: 0;
              max-width: 100%;
            }

            img {

              @media screen and (min-width: $tab + 1) {
                height: 350px;
              }
            }
          }
        }
      }

      &-middle {
        margin-bottom: 100px;

        @media screen and (max-width: $tab) {
          margin-bottom: 40px;
        }
      }

      &-lower {
        @include clearfix();
        margin-bottom: 100px;

        @media screen and (max-width: $tab) {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          flex-direction: column;
          padding: 0 20px;
          margin-bottom: 0;
        }

        .quote-text {
          margin-bottom: 100px;

          &__inner {
            padding-left: calc((100vw - 1100px) / 2);
          }
        }

        .tab__item {

          &:nth-child(even) {
            float: right;
          }

          &:nth-child(odd) {
            float: left;
          }

          &:nth-child(1n + 0) {
            max-width: 35%;

            @media screen and (max-width: $tab) {
              width: 100% ;
              margin: 0 0 40px;
              padding: 0;
              max-width: 100%;
            }
          }

          &:nth-child(2n + 0) {
            max-width: 60%;
            padding-right: 3%;
            margin-top: 100px;
            margin-bottom: 62px;

            @media screen and (max-width: $tab) {
              width: 100% ;
              margin: 0 0 40px;
              padding: 0;
              max-width: 100%;
            }

            img {

              @media screen and (min-width: $tab + 1) {
                height: 700px;
              }
            }
          }

          &:nth-child(3n + 0) {
            max-width: 40%;
            padding-left: 3%;
            padding-right: 5%;
            margin-bottom: 100px;

            @media screen and (max-width: $tab) {
              width: calc(100% + 40px);
              margin: 0 -20px 40px -20px;
              padding: 0;
              max-width: calc(100% + 40px);
            }

            figcaption {

              @media screen and (max-width: $tab) {
                padding-left: 20px;
              }
            }

            img {

              @media screen and (min-width: $tab + 1) {
                height: 350px;
              }
            }
          }

          &:nth-child(4n + 0) {
            max-width: 50%;
            padding-left: 3%;
            margin-top: 200px;

            @media screen and (max-width: $tab) {
              width: 100% ;
              margin: 0 0 40px;
              padding: 0;
              max-width: 100%;
            }

            img {

              @media screen and (min-width: $tab + 1) {
                height: 650px;
              }
            }
          }

          &:nth-child(5n + 0) {
            // margin-top: 200px;
            max-width: 50%;
            padding-left: 5%;

            @media screen and (max-width: $tab) {
              width: 100% ;
              margin: 0 0 40px;
              padding: 0;
              max-width: 100%;
            }
            // padding-right: calc((100vw - 1100px) / 2);
            // float: right;

            img {

              @media screen and (min-width: $tab + 1) {
                height: 550px;
              }
            }
          }
        }
      }
    }
  }
}

.full-width-img {

  img {
    width: 100%;
    height: auto;
  }
}
