.gal-slider {

  .modal_close {
    color: $white;
  }

  &::before { 
    background-color: $green;
  }

  &__outer, &__inner, &__container {
    height: 100%;
    width: 100%;
  }

  &__inner {
    padding: 100px 138px;
    position: relative;

    @media screen and (max-width: $tab) {
      padding: 100px 0;
    }
  }

  &__slide {
    @include bgContain();
  }

  &__nav {

    svg {
      path {
        fill: $white;
      }
    }
  }

  &__prev, &__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 10px;

    @media screen and (max-width: $tab) {
      top: auto;
      transform: translateY(0);
      bottom: 23px;
      z-index: 5;
    }

    .icon {
      position: relative;
    }

    &.swiper-button-disabled {
      pointer-events: none;
      opacity: .5;
    }
  }

  &__prev {
    left: 49px;

    .icon {
      right: 0px;
      transition: right .3s ease-in-out;
    }

    &:hover {

      .icon {
        right: 7px;
      }
    }
  }

  &__next {
    right: 49px;

    .icon {
      left: 0px;
      transition: left .3s ease-in-out;
    }

    &:hover {

      .icon {
        left: 7px;
      }
    }
  }

  &__pagination.swiper-pagination-fraction {
    position: absolute;
    bottom: 34px;
    text-align: center;
    font-size: 14px;
    color: rgba($color: $white, $alpha: 0.5);
    font-weight: 500;

    @media screen and (max-width: $tab) {
      z-index: 1;
    }
  }
}
