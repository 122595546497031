.has-questions {
  background-color: $white;

  .block {
    width: 50%;
    padding: 60px 0 87px;

    @media screen and (max-width: $tab) {
      width: 100%;
    }

    &:first-child {
      border-right: 1px solid $border-grey;

      @media screen and (max-width: $tab) {
        border-right: none;
        border-bottom: 1px solid $border-grey;
      }
    }

    .title {
      margin-bottom: 40px;

      @media screen and (max-width: $tab) {
        margin-bottom: 30px;
      }
    }

    &-left {

      @media screen and (max-width: $tab) {
        padding-bottom: 40px;
      }

      .figure {
        max-width: 324px;
      }
      .profile-img {
        max-width: 324px;
        margin-bottom: 20px;

        @media screen and (max-width: $tab) {
          width: 100%;
        }
      }

      .name {
        margin-bottom: 10px;
      }
    }

    &-right {
      padding-left: 92.5px;

      @media screen and (max-width: $tab) {
        padding: 40px 0 0;
      }

      form {
        max-width: 350px;

        @media screen and (max-width: $tab) {
          margin: 0 auto;
        }
      }
    }
  }
}
