.profile-head {
  padding: 50px 50px 30px;
  border-bottom: 1px solid $border-grey;

  @media screen and (max-width: $tab) {
    padding: 20px 20px 25px;
  }

  .user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 50px;

    &-details {
      text-align: right;

      &__name {
        font-size: 14px;
        font-weight: 500;
        line-height: em(20px, 14px);
        letter-spacing: em(.2px, 14px);
        margin-bottom: 7px;
      }

      &__logout {
        color: $green;
        font-size: 12px;
        font-weight: 500;
      }
    }

    &-img {
      height: 52px;
      width: 52px;
      border-radius: 50%;
      margin-left: 20px;
      background-color: $dark;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: em(.5px, 18px);
      line-height: 1.5em;
    }
  }

  .top-panel {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
    }

    .title {
      font-size: 32px;
      font-weight: 500;
      position: relative;
      padding-right: 34px;

      @media screen and (max-width: $tab) {
        margin-bottom: 25px;
      }

      &::after {
        content: attr(data-newmsgs);
        position: absolute;
        right: 0px;
        top: 10px;
        font-size: 14px;
        color: $white;
        background-color: $pink;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--noNew {

        &::after {
          display: none;
        }
      }
    }

    .switches {

      &__switch {
        display: flex;
        align-items: center;
        justify-content: flex-start;
  
        p {
          opacity: 1;
        }
  
        input {
          display: none;
        }
  
        label {
          position: relative;
          width: 42px;
          height: 24px;
          margin-right: 10px;
          color: $green;
          cursor: pointer;
  
          &::before {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 12px;
            background-color: rgba($color: $dark, $alpha: .2);
            display: block;
            transition: background-color .1s ease-in-out;
          }
  
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 2px;
            width: 20px;
            height: 20px;
            background-color: $white;
            border-radius: 50%;
            transition: .15s ease-in-out;
            transform: translateX(2px);
          }
        }
  
        input:checked ~ label {
  
          &::before {
            background-color: currentColor;
          }
  
          &::after {
            transform: translateX(20px);
          }
        }
      }
    }
  }
}

.profile-body {
  display: flex;
  position: relative;
}

.messages {
  height: 100%;
  display: inline-block;
  width: 378px;

  @media screen and (max-width: $tab) {
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    min-height: 100vh;
    height: 100%;
    background-color: $border-grey;
    top: 0px;
    left: 378px;

    @media screen and (max-width: $tab) {
      display: none;
    }
  }

  &.disabled {
    
    .messages-item {
      pointer-events: none;
      opacity: .95;
    }
  }

  &-item {
    padding: 23px 50px;
    border-bottom: 1px solid $border-grey;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &.unread {
      &::after {
        position: absolute;
        content: "";
        height: 8px;
        width: 8px;
        background-color: $pink;
        border-radius: 50%;
        top: 30px;
        right: 20px;
      }
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: em(.2px, 14px);
      line-height: em(20px, 14px);
      margin-bottom: 10px;
    }

    .date {
      margin-bottom: 15px;
    }

    .type {
      height: 30px;
      padding: 0 15px;
      background-color: $green;
      color: $white;
      display: inline-block;
      font-size: 12px;
      border-radius: 15px;
      line-height: 2.5em;
      letter-spacing: em(.2px, 12px);
    }

    &:hover, &--active {
      background-color: $green;
      border-color: $green;

      .title, .date {
        color: $white;
      }

      .type {
        background-color: $white;
        color: $green;
      }
    }
  }
}

.message-main {
  width: calc(100% - 378px);
  padding: 30px 100px 40px;

  @media screen and (max-width: $tab) {
    position: fixed;
    background-color: $light;
    right: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 30px 20px 0px;
    overflow-y: auto;
    transition: right .5s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  }

  &.opened {
    @media screen and (max-width: $tab) {
      right: 0;
    }
  }

  &__mob-hero {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;

    .go-back {
      display: flex;
      margin-bottom: 35px;

      .icon {
        margin-right: 20px;
      }
    }

    .title {
      font-size: 32px;
      font-weight: 500;
      line-height: em(42px, 32px);
    }

    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 40px);
      height: 1px;
      background-color: $border-grey;
      bottom: 0px;
      left: -20px;
    }
  }

  &__title {
    margin-bottom: 15px;
  }

  &__text {
    padding-bottom: 0px;
  }

  &__content + &__comments {
    margin-top: 40px;
  }

  .make-comment {
    display: none;

    &.disabled {
      opacity: .9;
      pointer-events: none !important;
    }

    @media screen and (max-width: $tab) {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
      overflow: hidden;
    }

    form {
      position: relative;
    }

    &__attach {
      height: 37px;
      width: 35px;
      position: absolute;
      top: 0px;
      left: 80px;
      z-index: 12;
      cursor: pointer;

      &-icon {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    [type="submit"] {
      font-family: $font;
      font-size: 12px;
      letter-spacing: em(.2px, 12px);
      font-weight: 500;
      background-color: transparent;
      border: none;
      color: $green;
      position: absolute;
      bottom: 32px;
      right: 30px;
      cursor: pointer;

      @media screen and (max-width: $tab) {
        right: 20px;
      }
    }
  }

  &[data-can-comment="true"] {
    .make-comment {
      display: block;
    }
  }

  .trumbowyg-editor[contenteditable=true]:empty:not(:focus)::before {
    font-size: 12px;
    letter-spacing: em(.2px, 12px);
    color: rgba($color: $dark, $alpha: .4);
  }

  .trumbowyg-box, .trumbowyg-button-pane {
    background-color: $white;
    border: none;
  }
  .trumbowyg-button-pane {
    border-bottom: 1px solid $light;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    &::after {
      display: none;
    }

    .trumbowyg-button-group {
      
      &::after {
        display: none;
      }
    }
  }
  .trumbowyg-box, .trumbowyg-editor {
    min-height: 80px;

    @media screen and (max-width: $tab) {
      margin-bottom: 0;
    }
  }
  .trumbowyg-box {

    svg {
      width: 21px;
    }
  }
  .trumbowyg-editor, .trumbowyg-textarea {
    padding: 30px 80px 30px 20px;
  }

  #trumbowyg-strong {

    line {
      stroke:#03474a;
      stroke-width:4.25px;
    }
  }
}

.comment {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  .article {
    max-width: 90%;

    @media screen and (max-width: $tab) {
      max-width: 97%;
    }
  }

  &--admin {
    justify-content: flex-start;

    .article {
      padding-left: 42px;

      .comment-text {
        background-color: $white;
        color: rgba($color: $dark, $alpha: .5);
      }

      .comment-date {
        text-align: left;
      }
    }
  }

  .img {
    position: absolute;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: $dark;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: em(.2px, 12px);
  }

  &-text {
    background-color: $green;
    opacity: 1;
    color: rgba($color: $white, $alpha: .6);
    padding: 10px 15px;
    font-size: 12px;
  }

  &-date {
    font-size: 10px;
    margin-top: 5px;
    text-align: right;
  }
}

.comment-text {

  h2 + p, p + ul, p + ol, h3 + table, p + table {
    margin-top: 30px;
  }

  p + p {
    margin-top: 20px;
  }

  p + h3, a + h3 {
    margin-top: 40px;
  }

  h3 + p, h3 + ol, h3 + ul {
    margin-top: 15px;
  }

  ul + p, ul + h2, ul + h3, ul + table {
    margin-top: 30px;
  }

  ol + p, ol + h2, ol + h3, ol + table {
    margin-top: 40px;
  }

  p {
    opacity: 1;
  }

  ul {

    li {
      font-size: 12px;
      line-height: em(20px, 12px);
      font-family: $font;
      font-weight: 400;
      padding-left: 20px;
      position: relative;
      color: rgba($color: $dark, $alpha: .5);
  
      &:not(:last-child) {
        margin-bottom: 1.1em;
      }
  
      &::after {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        background-color: $green;
        top: .5em;
        left: 0px;
      }
    }
  }

  ol {
    padding-left: 13px;
    

    li {
      font-size: 12px;
      line-height: em(20px, 12px);
      font-family: $font;
      font-weight: 500;
      position: relative;
      counter-increment: list;
      list-style-type: none;
      padding-left: 7px;
      color: rgba($color: $dark, $alpha: .5);

      &:not(:last-child) {
        margin-bottom: 1.1em;
      }

      &::before {
        color: $dark;
        content: counter(list) ".";
        left: -20px;
        position: absolute;
        text-align: right;
        width: 20px;
      }
    }
  }

  table {

    td,th {
      border: 1px solid $border-grey;
      font-size: 14px;
      line-height: em(20px, 14px);
      letter-spacing: em(.2px, 14px);
    }
    
    th {
      font-weight: 500;
      text-align: left;
      padding: 10px 20px;
    }

    td {
      padding: 20px;
    }
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  img {
    max-width: 100%;
  }

  &--neg {

    a {
      color: rgba($color: $white, $alpha: .6);
    }

    ul {
      li {
        color: rgba($color: $white, $alpha: .6);

        &::after {
          background-color: rgba($color: $white, $alpha: .6);
        }
      }
    }

    ol {
      li, li::before {
        font-weight: 400;
        color: rgba($color: $white, $alpha: .6);
      }
    }
  }

  a {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 0;
      background-color: currentColor;
      left: 0;
      bottom: -2px;
      transition: .3s cubic-bezier(.175,.885,.32,1.275);
    }

    &:hover {

      &::after {
        width: 100%;
      }
    }
  }
}


