.contact-details {
  padding-bottom: 60px;
  position: relative;

  @media screen and (max-width: $tab) {
    padding-bottom: 40px;
  }

  &__inner {
    padding-top: 158px;

    @media screen and (max-width: $tab) {
      padding-top: 110px;
    }
  }

  &__headline {
    margin-bottom: 80px;

    @media screen and (max-width: $tab) {
      margin-bottom: 40px;
    }
  }

  &__list {
    display: flex;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-right: 120px;

    @media screen and (max-width: $tab) {
      width: 100%;
      margin: 0 0 23px;
    }

    .icon {
      height: 24px;
      margin-bottom: 20px;

      svg, path {
        fill: $green;;
      }
    }

    .prefix {

      &::before {
        font-size: 14px;
        color: $green;
        font-weight: 500;
        line-height: em(20px, 14px);
      }

      .link {
        font-size: 14px;
        line-height: em(20px, 14px);
        opacity: 1;
        font-weight: 500;
      }

      & + .extra-link {
        margin-top: 5px;
      }
    }

    .extra-link {

      a {
        font-size: 12px;
        padding-left: 20px;
        font-weight: 500;
      }
    }
  }
}

.map {
  background-color: $dark;
  height: 650px;

  @media screen and (max-width: $tab) {
    height: 100vh;
    max-height: 650px;
  }

  .contacts__mapWrap {
    height: 100%;
  }
}

.socials {
  position: absolute;
  width: 207px;
  right: 0px;
  bottom: 110px;

  &__wrap {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 30px;
  }

  &__link {
    display: inline-block;
    padding: 0 10px;

    svg {
      height: 25px;

      path {
        fill: $dark;
        transition: .3s ease-in-out;
      }
    }

    & + & {
      margin-top: 30px;
    }

    &:hover {

      path {
        fill: $green;
      }
    }
  }
}

.mob-socials {
  padding: 28px 0;

  &__wrap {
    display: flex;
    justify-content: center
  }

  &__title {
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
  }

  &__link {
    margin-left: 30px;
  }
}
