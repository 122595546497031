body {
  background-color: $light;
  color: $black;
  font-family: $font;
  font-weight: 400;
  font-size: $basepx;
  position: relative;

  &.logged, &.interior {

    &::before {
      display: none;
    }
  }

  &.fixed {
    height: 100%;
    overflow: hidden;
  }

  &::before {
    content: "";
    position: absolute;
    width: 207px;
    height: 100%;
    background-color: $white;
    z-index: -1;
    right: 0px;
    top: 0px;

    @media screen and (max-width: $tab) {
      display: none;
    }
  }
}

.container {
  max-width: $lg;
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;

  @media screen and (max-width: $tab) {
    padding: 0 20px;
  }
}

.flexwrap {
  display: flex;

  @media screen and (max-width: $tab) {
    flex-wrap: wrap;
  }
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.no-mob {
  @media screen and (max-width: $tab) {
    display: none !important;
  }
}

.only-mob {
  @media screen and (min-width: $tab + 1px) {
    display: none !important;
  }
}

.quotes {
  margin-bottom: 40px;

  @media screen and (max-width: $tab) {
    margin-bottom: 30px;
  }
}

.prefix {
  padding-left: 20px;
  position: relative;
  opacity: 1;

  @media screen and (max-width: $tab) {
    opacity: 1;
  }

  &::before {
    content: attr(prefix);
    position: absolute;
    left: 0;
    font-size: 12px;
    font-family: $font;
    font-weight: 400;
  }

  .link {
    font-size: 12px;
    font-weight: 400;
    line-height: em(20px, 12px);
    color: $dark;
    opacity: .5;
  }
}

#preloader {
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $light;
  opacity: .7;
  z-index: 110;
  pointer-events: none;
}

.tableOverflowWrap {
  width: 100%;
  overflow-x: scroll;
}

.scroll-icon {
  margin: 30px auto 0;
  text-align: center;
  padding: 30px 0;

  &__anim {
    display: inline-block;
    bottom: -6px;
    position: relative;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-name: slide;
  }

  & + .tableOverflowWrap {

    @media screen and (min-width: $tab + 1) {
      margin-top: 30px;
    }
  }
}

.figure {
  position: relative;

  &-wrap {
    position: relative;

    &::after {
      @include animatedOverlay();
    }
  }

  &.found {

    .figure-wrap {
      &::after {
        @include animatedOverlayAnim();
      }
    }
  }

  img {
    width: 100%;
  }
}

[data-slide-in] {
  transform: translateY(60px) scale(.95);
  transform-origin: center center;
  transition: .5s ease-in-out;
  opacity: 0;

  &.found {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
}

img.fit {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
