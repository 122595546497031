.apataments-list {
  position: relative;

  @media screen and (max-width: $tab) {
    background-color: $white;
  }

  &__table {

    @media screen and (max-width: $tab) {
      overflow-x: scroll;
    }
  }
}

.filters {
  padding: 60px 0;
  background-color: $white;

  @media screen and (max-width: $tab) {
    padding: 0px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .container {
    @media screen and (max-width: $tab) {
      padding: 40px 20px 50px;
    }
  }

  &__wrap {
    display: flex;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
    }
  }

  &__item {
    width: 27%;
    padding-bottom: 35px;

    @media screen and (max-width: $tab) {
      width: 100%;
      margin-bottom: 20px;
    }

    &:last-child {
      width: 19%;

      @media screen and (max-width: $tab) {
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .title {
      margin-bottom: 20px;
    }
  }

  &-toggle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .text {
      margin-right: 20px;
      font-size: 14px;
      color: $green;
      letter-spacing: em(.2px, 14px);
      font-weight: 500;
    }

    .icon {
      transform: rotate(180deg);
      transition: transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &.active {

      .icon {
        transform: rotate(0deg);
      }
    }
  }
}

.floor-map {
  position: relative;

  .building {
    margin: 0 auto;
  }

  polygon {
    fill: $green;
    opacity: 0;
    transition: .2s ease-in-out;
    cursor: pointer;

    &:hover {

      opacity: .5;
    }
  }

  .container {
    position: relative;
  }

  .scroll-down {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    small {
      writing-mode: vertical-lr;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: em(.2px, 12px);
      margin-bottom: 20px;
    }

    #mouse {
      height: 24px;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-name: scroll;
    }
  }
}

.apataments-list {

  .list-head {
    background-color: $black;

    @media screen and (max-width: $tab) {
      width: calc(140px * 6 + 40px);
      display: flex;
    }

    &__item {
      padding: 20px 0 19px;
      width: calc(100% / 6);
      color: $white;
      text-align: center;

      @media screen and (max-width: $tab) {
        width: 140px;
      }

      p {
        font-size: 14px;
        opacity: 1;
        font-weight: 500;
        letter-spacing: em(.2px, 14px);
        cursor: pointer;
        display: inline-block;
      }
    }

    .icon {
      margin-left: 10px;
      position: relative;
      top: 3px;

      svg, path {
        fill: $white;
      }
    }    
  }

  .list-body {
    background-color: $white;

    @media screen and (max-width: $tab) {
      width: calc(140px * 6 + 40px);
      display: flex;
      flex-wrap: wrap;
    }

    &__row {
      border-bottom: 1px solid $border-grey;
      cursor: pointer;
      background-color: transparent;
      transition: .2s ease-in-out;

      .container {
        display: flex;
        position: relative;
      }
    }

    &__item {
      width: calc(100% / 6);
      padding: 26px 0;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $tab) {
        width: 140px;
        padding: 20px 0 15px;
      }

      p {
        font-size: inherit;
        color: inherit;
        opacity: 1;
        letter-spacing: em(.2px, 12px);
        font-weight: inherit;
        transition: .2s ease-in-out;
      }

      &--nr {
        font-size: 32px;
        color: $green;
        font-weight: 500;

        @media screen and (max-width: $tab) {
          font-size: 24px;
        }
      }
    }

    &__row {

      &:hover {
        background-color: $green;

        p {
          color: $white;
        }
      }

      &.taken {
        pointer-events: none;

        p {
          opacity: .5;
        }

        .availability {
          color: red;
        }
      }
    }
  }
}

#apartament-list {

  &.loading {
    opacity: .90;
    pointer-events: none;
  }
}
