h1, .headline {
  font-size: 62px;
  line-height: em(72px, 62px);
  font-weight: 500;

  @media screen and (max-width: $tab) {
    font-size: 38px;
    line-height: em(48px, 38px);
  }
}

h2, .pr_title {
  font-size: 32px;
  line-height: em(42px, 32px);
  font-weight: 500;

  @media screen and (max-width: $tab) {
    font-size: 24px;
    line-height: em(34px, 24px);
    letter-spacing: em(.2px, 24px);
  }

  &--light {
    color: $white;
  }
}

h3, .sec_title {
  font-size: 22px;
  line-height: em(32px, 22px);
  font-weight: 500;

  @media screen and (max-width: $tab) {
    font-size: 18px;
    line-height: em(28px, 18px);
    letter-spacing: em(.2px, 18px);
  }
}

h4, .sm_title {
  font-size: 18px;
  line-height: em(26px, 18px);
  letter-spacing: em(.2px, 18px);
  font-weight: 500;

  @media screen and (max-width: $tab) {
    font-size: 16px;
    line-height: em(24px, 16px);
    letter-spacing: em(.2px, 16px);
  }
}

p, .body_txt {
  font-size: 12px;
  line-height: em(20px, 12px);
  letter-spacing: em(.2px, 12px);
  font-weight: 400;
  opacity: .5;
}

a {
  color: $green;
}

h1 + h4 {
  margin-top: 20px;
}

figcaption {
  font-size: 12px;
  font-family: $font;
  letter-spacing: em(.2px, 12px);
  line-height: em(20px, 12px);
  font-weight: 400;
  color: rgba($color: $dark, $alpha: .5);
  margin-top: 20px;
}

.sup {
  position: relative;
 
  &::after {
    content: "2";
    position: absolute;
    right: -10px;
    top: -5px;
    font-size: .7em;
  }
}
