@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-Bold');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-BoldItalic');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-ExtraBold');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-ExtraBoldItalic');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-Light');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-LightItalic');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-Medium');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-MediumItalic');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-Regular');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-RegularItalic');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-SemiBold');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-SemiBoldItalic');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-Thin');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-ThinItalic');
  font-weight: 200;
  font-style: italic;
}
