.text-content {
  padding-bottom: 107px;

  @media screen and (max-width: $tab) {
    padding-bottom: 60px;
  }

  &__wrap {
    width: 80%;

    @media screen and (max-width: $tab) {
      width: 100%;
    }
  }

  h2 + p, p + ul, p + ol, h3 + table, p + table {
    margin-top: 30px;
  }

  p + p {
    margin-top: 20px;
  }

  p + h3 {
    margin-top: 40px;
  }

  h3 + p, h3 + ol, h3 + ul {
    margin-top: 15px;
  }

  ul + p, ul + h2, ul + h3, ul + table {
    margin-top: 30px;
  }

  ol + p, ol + h2, ol + h3, ol + table {
    margin-top: 40px;
  }

  ul {

    li {
      font-size: 12px;
      line-height: em(20px, 12px);
      font-family: $font;
      font-weight: 400;
      padding-left: 20px;
      position: relative;
      color: rgba($color: $dark, $alpha: .5);
  
      &:not(:last-child) {
        margin-bottom: 1.1em;
      }
  
      &::after {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        background-color: $green;
        top: .5em;
        left: 0px;
      }
    }
  }

  ol {
    padding-left: 13px;
    

    li {
      font-size: 12px;
      line-height: em(20px, 12px);
      font-family: $font;
      font-weight: 500;
      position: relative;
      counter-increment: list;
      list-style-type: none;
      padding-left: 7px;
      color: rgba($color: $dark, $alpha: .5);

      &:not(:last-child) {
        margin-bottom: 1.1em;
      }

      &::before {
        color: $dark;
        content: counter(list) ".";
        left: -20px;
        position: absolute;
        text-align: right;
        width: 20px;
      }
    }
  }

  table {

    td,th {
      border: 1px solid $border-grey;
      font-size: 14px;
      line-height: em(20px, 14px);
      letter-spacing: em(.2px, 14px);
    }
    
    th {
      font-weight: 500;
      text-align: left;
      padding: 10px 20px;
    }

    td {
      padding: 20px;
    }
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  img {
    max-width: 100%;
  }

  a {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 0;
      background-color: currentColor;
      left: 0;
      bottom: -2px;
      transition: .3s cubic-bezier(.175,.885,.32,1.275);
    }

    &:hover {

      &::after {
        width: 100%;
      }
    }
  }
}
