.about-hero {

  &__images {
    display: flex;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
      justify-content: center;
    }

    &-main {
      width: 55%;
      height: 100%;

      @media screen and (max-width: $tab) {
        width: 100%;
        margin-bottom: 40px;
      }
    }

    &-sec {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $tab) {
        width: calc(100% - 40px);
        margin-bottom: 60px;
      }

      .figure {
        width: 70%;

        @media screen and (max-width: $tab) {
          width: 100%;
        }

        img {
          width: 100%;

          @media screen and (min-width: $tab + 1) {
            height: 350px;
          }
        }
      }
    }
  }
}

.about-quotes {
  background-color: $dark;
  padding: 100px 0;

  @media screen and (max-width: $tab) {
    padding: 60px 0;
  }

  .text {
    column-count: 2;
    column-gap: 100px;
    letter-spacing: unset;

    @media screen and (max-width: $tab) {
      column-count: 1;
    }
  }
}

.about-story {
  background-color: $white;
  padding: 100px 0;

  @media screen and (max-width: $tab) {
    padding: 40px 0 60px;
  }

  .left, .right {
    width: 50%;

    @media screen and (max-width: $tab) {
      width: 100%;
    }

    .figure {

      img {
        width: 100%;
      }
    }

    .sec_title + .text-content {
      margin-top: 15px;
    }

    .text-content + .figure, .figure + .quote-text {
      margin-top: 100px;

      @media screen and (max-width: $tab) {
        margin-top: 40px;
      }
    }

    .figure + .sec_title {
      margin-top: 40px;
    }
  }

  .left {
    padding-left: calc((100vw - 1200px) / 2);

    &-inner {
      padding: 0 120px 0 50px;

      @media screen and (max-width: $tab) {
        padding: 0 20px;
      }
    }

    .figure {

      img {

        @media screen and (min-width: $tab + 1) {
          height: 650px;
        }
      }
    }
  }

  .right {
    padding-right: 5%;

    @media screen and (max-width: $tab) {
      margin-top: 40px;
      padding: 0 20px;
    }

    .sec_title, .text-content {
      padding-right: calc((100vw - 1100px) / 2);
    }

    .figure.first {
      width: 95%;

      @media screen and (max-width: $tab) {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
      }

      figcaption {
        @media screen and (max-width: $tab) {
          padding-left: 20px;
        }
      }

      img {

        @media screen and (min-width: $tab + 1) {
          height: 550px;
        }

        @media screen and (min-width: $xl + 1) {
          height: 650px;
        }
      }
    }

    .figure {
      width: 70%;

      @media screen and (max-width: $tab) {
        width: 100%;
        margin: 0;
      }

      img {

        @media screen and (max-width: $tab) {
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
        }

        @media screen and (min-width: $tab + 1) {
          height: 350px;
        }

        @media screen and (min-width: $xl + 1) {
          height: 450px;
        }

      }
    }

    .text-content + .figure {
      margin-top: 180px;

      @media screen and (max-width: $tab) {
        margin-top: 40px;
      }
    }
  }
}
