.footer {  
  height: 100px;
  background-color: $white;
  padding: 0px 70px 0px 60px;
  border-top: 1px solid $border-grey;

  @media screen and (max-width: $tab) {
    height: auto;
    padding: 30px 20px;
  }
  
  .flexwrap {
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .copyright {
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
    }

    p {
      opacity: 1;
      @media screen and (max-width: $tab) {
        width: 100%;
        opacity: 1;
        margin-bottom: 5px;
      }
    }

    & > a {
      font-size: 12px;
      margin-left: 5px;
      line-height: em(20px, 12px);
      letter-spacing: em(.2px, 12px);
      font-weight: 500;

      @media screen and (max-width: $tab) {
        margin: 0 0 20px 0;
      }
    }
  }

  .created {

    p {
      opacity: 1;
      @media screen and (max-width: $tab) {
        opacity: 1;
      }
    }

    .imagine_logo {
      margin-left: 10px;

      svg {
        height: 12px;
        width: auto;

        path {
          fill: $dark;
        }
      }
    }
  }
}
