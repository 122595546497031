.btn__pr {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font;
  font-size: 18px;
  line-height: em(26px, 18px);
  letter-spacing: em(.2px, 18px);
  font-weight: 400;
  padding: 0 43px;
  height: 80px;
  cursor: pointer;
  transition: background-color .3s ease-in-out;

  @media screen and (max-width: $tab) {
    padding: 0;
    font-size: 16px;
    width: 100%;
  }

  .icon {
    margin-right: 15px;
    position: relative;
    top: 2px;
    // height: 18px;
    width: auto;
    display: inline-block;
  }
  

  &--neg {
    background-color: $white;

    .icon {
      path {
        fill: $green;
      }
    }

    &:hover {
      background-color: $light;
    }
  }

  &--pos {
    background-color: $green;
    color: $white;

    .icon {
      path {
        fill: $white;
      }
    }

    &:hover {
      background-color: $green--hover;
    }
  }
}

h4 + .btn_pr {
  margin-top: 40px;
}

.download-file {
  display: inline-block;
  padding-left: 34px;
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 15px;

  &::after {
    content: "";
    position: absolute;
    height: 24px;
    width: 24px;
    top: -8px;
    left: 0px;
    background-image: url(../../src/img/ic_download.svg);
  }
}
