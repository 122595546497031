.modal-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
  padding-bottom: 100px;

  @media screen and (max-width: $tab) {
    padding-bottom: 60px;
  }

  &__item {
    width: 100%;
    height: 650px;
    position: relative;
    overflow: hidden;
    z-index: 2;
    cursor: pointer;

    @media screen and (max-width: $tab) {
      height: 450px;
    }

    @media screen and (max-width: $sm) {
      height: 180px;
    }

    &::after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      border: 8px solid $light;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &--half {
      width: 50%;

      @media screen and (max-width: $sm) {
        width: 100%;
        height: 360px;
      }
    }
  }

  &__img {
    position: relative;
    height: 100%;
    width: 100%;
    @include bgCover();
    z-index: 1;
    transition: transform .3s ease-in-out;
  }

  &__overflow {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 0px;
    left: 0px;
    background-color: rgba($color: $dark, $alpha: .2);
    opacity: 0;
    transition: opacity .5s ease-in-out;

    .icon {
      text-align: center;
      margin-bottom: 20px;

      path {
        fill: $white;
      }
    }

    .text {
      font-size: 14px;
      font-weight: 500;
      color: $white;
      letter-spacing: em(.2px, 14px);
    }
  }

  &__item {

    &:hover {

      .modal-gallery__img {
        transform: scale(1.05);
        transform-origin: center center;
      }

      .modal-gallery__overflow {
        opacity: 1;
      }

    }
  }
}
