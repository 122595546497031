.not-found {
  height: 100vh;
  width: 100%;

  &__outer {
    display: flex;
    justify-content: center;
    height: 100%;

    @media screen and (max-width: $tab) {
      padding: 0 20px;
      position: relative;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $tab) {
      width: 100%;
      position: relative;
    }
  }

  &__content {
    text-align: center;
  }

  .icon-big {
    margin-bottom: 40px;

    svg {
      @media screen and (max-width: $tab) {
        height: 80px;
      }
    }
  }

  .msg {
    font-size: 22px;
    line-height: em(32px, 22px);
    letter-spacing: em(.2px, 22px);
    color: $dark;
    opacity: 1;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .btn__pr {

    @media screen and (max-width: $tab) {
      position: absolute;
      left: 0px;
      bottom: 20px;
    }
  }
}
