.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: none;



  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 200%;
    background-color: $light;
    z-index: -1;
  }

  &_outer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  &_close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 35px 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $dark;
    cursor: pointer;
    z-index: 5;
    transition: .3s ease-in-out;

    @media (max-width: $md - 1px) {
      padding: 23px 35px;
    }

    &:hover {
      color: $green;
    }

    small {
      font-size: 14px;
      letter-spacing: em(.2px, 14px);
      line-height: em(20px, 14px);
      font-weight: 500;

      // @media (max-width: $md - 1px) {
      //   display: none;
      // }
    }

    &-cross {
      width: 24px;
      height: 24px;
      display: block;
      position: relative;
      margin-left: 20px;

      &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: center;
        width: 30px;
        height: 2px;
        background-color: currentColor;
      }

      &::before {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      }

      &::after {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }
    }
  }

  &_inner {
    max-height: 100%;
    width: 100%;
    overflow: auto;
    padding: 50px 0;

    @media screen and (max-width: $tab) {
      padding: 85px 0 20px;
    }

    &.double {
      height: 100%;
      padding: 0;
    }

    .container {
      display: flex;

      .form {
        margin: 0 auto;
        width: 100%;
        max-width: 400px;

        form {
          margin: 0 auto;
        }
      }
    }
  }

  &__title {
    text-align: center;
    letter-spacing: unset;
    margin-bottom: 40px;

    @media screen and (max-width: $tab) {
      text-align: left;
    }
  }

  &#search {
    &::before {
      background-color: $dark;
    }

    .modal {
      &_close {
        color: $white;
      }

      &_suggests {
        position: absolute;
        bottom: 50px;
        left: 0;
        width: 100%;

        @media (max-width: $md - 1px) {
          bottom: 40px;
        }

        p {
          color: $white;
          padding: 0 50px;

          @media (max-width: $md - 1px) {
            padding: 0;
          }

          span {
            opacity: 0.5;
            cursor: pointer;
            display: inline-block;
            margin-left: 20px;
            transition: 0.2s ease-in-out;

            @media (max-width: $md - 1px) {
              margin-left: 0;
              display: block;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }
      
      &_inner {
        .container .form {
          max-width: none;

          &_input {
            position: relative;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              width: 50px;
              height: 70px;
              top: 0;
              // background-image: url(../img/search.svg);
              background-size: 24px 24px;
              background-repeat: no-repeat;
              background-position: center left;
              opacity: 0.3;

              @media (max-width: $md - 1px) {
                width: 35px;
                height: 50px
              }
            }

            input {
              border-bottom: 2px solid rgba($black, 0.3);
              font-size: 38px;
              font-weight: 700;
              color: $white;
              height: 72px;
              padding-left: 50px;

              @media (max-width: $md - 1px) {
                font-size: 24px;
                height: 50px;
                padding-left: 35px;
              }
            }

            label {
              color: rgba($black, 0.3);
              font-size: 38px;
              font-weight: 700;
              opacity: 1;
              line-height: 54px;
              padding-bottom: 9px;
              padding-left: 50px;

              @media (max-width: $md - 1px) {
                font-size: 24px;
                line-height: 30px;
                padding-bottom: 10px;
                padding-left: 35px;
              }
            }

            &.focus input, &.full input, input:focus {
              & + label {
                padding-bottom: 60px;
                font-size: 14px;
                line-height: 20px;

                @media (max-width: $md - 1px) {
                  padding-bottom: 42px;
                }
              }
            }
          }
        }
      }
    }
  }

  .double-wrap {
    display: flex;
    height: 100%;
    width: 100%;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
    }

    &__item {
      width: 50%;
      height: 100%;
      position: relative;

      @media screen and (max-width: $tab) {
        width: 100%;
      }

      .overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba($dark, 0.2);
        z-index: 1;
      }
    }

    .left {
      display: flex;
      align-items: center;
      @include bgCover();

      @media screen and (max-width: $tab) {
        order: 2;
      }

      &-content {
        max-width: 324px;
        margin: 0 auto;
        z-index: 2;
        // padding: 0 25%;
        @media screen and (max-width: $tab) {
          padding: 0 20px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      @media screen and (max-width: $tab) {
        height: auto;
        order: 1;
      }

      .form {
        width: 50%;
        max-width: 350px;
        margin: 0 auto;

        @media screen and (max-width: $tab) {
          width: 100%;
          max-width: 100%;
          padding: 85px 20px 60px;
        }
      }
    }
  }

  .go-back-link {
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: em(.2px, 12px);
  }
}

.reservation {
  z-index: 100;
}

.compass {
  position: absolute;
  bottom: 50px;
  right: 60px;
}

.mob-compass {
  margin: 0 0 20px auto;
  padding-right: 20px;
}

.reservIncluded {
  color: $dark;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 500;
}
