.be-first {
  height: 650px;
  width: 100%;
  background-color: $green;

  @media screen and (max-width: $tab) {
    height: auto;
  }

  .flexwrap {
    height: 100%;
  }

  &__left, &__right {
    width: 50%;
    height: 100%;

    @media screen and (max-width: $tab) {
      width: 100%;
    }
  }

  &__left {
    @include bgCover();
    position: relative;

    &::after {
      @include animatedOverlay();
    }

    &.found {
      &::after {
        @include animatedOverlayAnim();
      }
    }

    @media screen and (max-width: $tab) {
      height: 320px;
      order: 1;
    }
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tab) {
      padding: 125px 20px;
      height: auto;
      order: 2;
    }
  }
}
