.ap-modal {
  overflow-y: scroll;
  background-color: $light;

  &::before {
    height: 100%;
  }

  &__inner {
    width: 100%;
    padding-left: 462px;

    @media screen and (max-width: $tab) {
      padding-left: 0;
    }
  }

  &__hero {
    height: 100vh;
    min-height: 800px;
    width: 100%;
    display: flex;

    @media screen and (max-width: $tab) {
      height: auto;
      flex-wrap: wrap;
    }
  }

  &__table {
    background-color: $green;
    padding: 105px 81px;
    width: 462px;
    position: fixed;
    left: 0;
    height: 100%;
    top: 0;
    overflow-x: scroll;

    @media screen and (max-width: $tab) {
      order: 2;
      padding: 20px 20px 40px;
      width: 100%;
      position: relative;
      margin-top: 70px;
      overflow-x: unset;
    }

    .details {
      margin-bottom: 25px;
      width: 100%;

      @media screen and (max-width: $tab) {
        margin-bottom: 40px;
      }

      td {
        border: 1px solid rgba($color: $white, $alpha: 0.2);
        padding: 30px 20px;
      }

      .big-title,
      .small-title {
        font-weight: 500;
        color: $white;
        opacity: 1;
      }

      .icon {
        margin-bottom: 10px;

        path {
          fill: $white;
        }
      }

      .small-title {
        font-size: 14px;
        letter-spacing: em(0.2px, 14px);
        line-height: em(20px, 14px);

        & + .small-title {
          margin-top: 5px;
        }

        & + .big-title {
          margin-top: 15px;
        }
      }

      .big-title {
        font-size: 62px;
        line-height: em(72px, 62px);

        @media screen and (max-width: $tab) {
          font-size: 38px;
          line-height: em(48px, 38px);
        }
      }
    }

    .compass {
      position: relative;
      bottom: 0;
      right: 0;

      .icon {
        text-align: center;
      }

      img {
        max-width: 73px;
        max-height: 88px;
        margin: 0 auto;
      }
    }
  }

  &__table-th {
    vertical-align: bottom;
  }

  .included {
    font-weight: 500;
    color: $white;
    opacity: 1;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
  }

  &__plan {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media screen and (max-width: $tab) {
      padding: 50px 20px 0;
    }

    @media screen and (max-width: $sm) {
      padding: 20px 20px 0;
    }

    &-img {
      height: 350px;
      width: 490px;
      @include bgContain();

      @media screen and (max-width: $tab) {
        height: 350px;
        padding: 87px 0 20px;
        box-sizing: content-box;
      }

      @media screen and (max-width: $sm) {
        height: 200px;
        padding: 87px 0 20px;
        box-sizing: content-box;
      }
    }
  }

  &__description {
    padding: 80px 0;

    @media screen and (max-width: $tab) {
      padding: 60px 0 0;
    }

    &-item {
      width: 50%;

      @media screen and (max-width: $tab) {
        width: 100%;
      }

      &.left {
        padding-right: 15%;

        @media screen and (max-width: $tab) {
          padding-right: 0;
        }
      }

      &.right {
        padding-top: 75px;

        @media screen and (max-width: $tab) {
          padding-top: 60px;
        }
      }
    }
  }
}
