@include keyframes(rotation) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: rotation;
}

@keyframes scroll {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
  }
}

@keyframes slide {
  0% {
    left: 0;
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    left: -10px;
    opacity: 0;
  }
}

@keyframes imgOverlay {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

