$xl: 1440px;
$lg: 1200px;
$md: 992px;
$tab: 768px;
$sm: 540px;
$basepx: 14px;

$font: 'Beatrice', sans-serif;

$white:  #FFF;
$black:  #000;
$green: #02474A;
$green--hover: #0A2D2F;
$dark: #131514;
$light: #F4F4F7;
$pink: #F7647C;
$border-grey: #CFD0D0;
