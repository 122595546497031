.cookie {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: $green;
  padding: 10px 0px;
  z-index: 99;
  transition: .5s cubic-bezier(0.6, -0.28, 0.735, 0.045);

  @media screen and (max-width: $tab) {
    padding: 30px 20px 20px;
  }

  &-container {
    padding: 0 50px;

    @media screen and (max-width: $tab) {
      padding: 0;
    }
  }

  p {
    font-size: 12px;
    line-height: em(20px, 12px);
    letter-spacing: em(.2px, 12px);
    font-weight: 400;
    max-width: 75%;
    color: rgba($color: $white, $alpha: .5);
    opacity: 1;

    @media screen and (max-width: $tab) {
      width: 100%;
      order: 1;
      max-width: 100%;
      font-size: 14px;
    }

    a {
      color: $white;
      font-weight: 500;
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
    }
  }

  .btn__pr {
    padding: 17px 40px 16px;

    @media screen and (max-width: $tab) {
      margin-top: 30px;
      order: 2;
    }
  }

  &.closed {
    opacity: 0;
    transform: translateY(100px);
    z-index: -99;
  }
}
