.form {
  position: relative;
  min-height: 450px;

  &_hiddens {
    display: none !important;
  }

  // &__title {
  //   color: $dark;
  //   font-family: $font;
  //   font-size: 38px;
  //   line-height: 44px;
  //   font-weight: 400;

  //   @media screen and (max-width: $tab) {
  //     font-size: 26px;
  //     font-weight: 500;
  //   }
  // }

  &_input {
    position: relative;
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;

    &:not(:first-child):not(.form_input-checkbox) {
      margin-top: 32px;

      @media screen and (max-width: $tab) {
        // margin-top: 32px;
      }
    }

    input, label {
      font-family: $font;
      color: rgba($color: $dark, $alpha: .5);
      font-size: 12px;
      font-weight: 400;
      line-height: em(20px, 12px);
      letter-spacing: em(.2px, 12px);

      @media screen and (max-width: $tab) {
        // font-size: 22px;
      }
    }

    input {
      color: rgba($color: $dark, $alpha: 1);
      width: 100%;
      height: 36px;
      border: 0;
      margin: 0;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      border-bottom: 1px solid rgba($color: $dark, $alpha: .2);
      position: relative;
      z-index: 0;

      @media screen and (max-width: $tab) {
        // height: 42px;
      }
    }

    label {
      position: absolute;
      left: 0;
      bottom: -2px;
      line-height: 30px;
      padding-top: 4px;
      padding-bottom: 6px;
      z-index: 1;
      opacity: 1;
      transition: .3s cubic-bezier(.2,.6,.3,1);
      cursor: text;
      user-select: none;
      pointer-events: none;
    }

    &.full, &.focus, input:focus {
      input + label {
        font-size: 10px;
        padding-bottom: 25px;
        opacity: .5;

        @media screen and (max-width: $tab) {
          // padding-bottom: 25px;
        }
      }

      
    }

    &.focus, input:focus {

      input {
        border-bottom: 1px solid $green;
      }
    }

    &-checkbox {
      margin-top: 20px;

      & + & {
        margin-top: 0;
      }

      &.error + & {
        padding-top: 20px;
      }

      &.error {
        .error_msg {
          padding-top: 0;

          &::before {
            display: none;
          }
        }

        label::before {
          background-color: $pink;
        }
      }

      label {
        font-family: $font;
        font-size: 12px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding: 4px 0 0 35px;
        opacity: 1;
        pointer-events: auto;
        line-height: em(20px, 12px);

        &:hover {

          &::before {
            border-color: $green !important;
            border-width: 2px !important;
          }
        }

        &::before, &::after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          left: 0;
          top: 5px;
        }

        a {
          color: $green;
          font-weight: 500;
        }

        &::before, &::after {
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          left: 0;
          top: 2px;
        }

        &::before {
          border-color: $green;
          background-color: $green;
          transition: background-color .3s ease-in-out, border-color .3s ease-in-out, border-width .2s ease-in-out;
        }

        &::after {
          background-image: url(../img/yes.svg);
          background-repeat: no-repeat;
          background-size: 13px 13px;
          background-position: 5.5px center;
          opacity: 1;
          transition: 0.3s ease-in-out;
        }
      }

      input[data-check] {
        display: none;

        &:not(:checked) + label::after {
          width: 0px;
          opacity: 0;
        }

        &:not(:checked) + label::before {
          border: 1px solid rgba($color: $dark, $alpha: .2);
          background-color: transparent;
        }
      }
    }

    &.error {
      .error_msg {
        color: $pink;

        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }

    .error_msg {
      font-family: $font;
      position: absolute;
      width: 100%;
      top: calc(100% - 2px);
      left: 0;
      font-size: 10px;
      line-height: em(20px, 10px);
      letter-spacing: em(.2px, 10px);
      padding-top: 5px;
      color: transparent;
      transition: color 0.2s ease-in-out 0.1s;
      z-index: 2;
      text-align: left;

      &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 0;
        height: 1px;
        opacity: 0;
        background-color: $pink;
        transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;
      }
    }

    &-checkbox {

      .error_msg {
        top: calc(100% + 3px);
      }
    }
  }

  // &_input-file {

  //   &::before {
  //     content: "";
  //     width: 11px;
  //     height: 40px;
  //     position: absolute;
  //     right: 10px;
  //     top: 0;
  //     // background-image: url(../img/ic_upload.svg);
  //     background-position: 50%;
  //     background-repeat: no-repeat;
  //     background-size: contain;

  //     @media screen and (max-width: $tab) {
  //       width: 16px;
  //       top: 0px;
  //     }
  //   }

  //   input[data-file] {
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     opacity: 0;
  //     cursor: pointer;
  //     z-index: 1;
  //   }

  //   input[data-text] {
  //     pointer-events: none;
  //     z-index: 0;
  //     padding-right: 30px;
  //   }
  // }

  @-webkit-keyframes autofill {
    to {
        color: $dark;
        background: transparent;
    }
  }
  
  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  &_message {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    background-color: $light;
    z-index: 5;

    @media screen and (max-width: $tab) {
      padding: 0 20px;
    }

    .main-icon {
      height: 150px;
      width: 150px;
      @include bgContain();
      margin-bottom: 40px;

      @media screen and (max-width: $tab) {
        height: 120px;
        width: 120px;
      }
    }

    &__text {
      font-size: 22px;
      line-height: em(32px, 22px);
      font-weight: 500;
      letter-spacing: em(.2px, 22px);
      margin-bottom: 40px;
      text-align: center;
    }

    &-success {

      .main-icon {
        background-image: url(../img/ic_success_face.svg);
      }
    }

    &-error {

      .main-icon {
        background-image: url(../img/ic_error_face.svg);
      }
    }
  }
}

.form_submit {
  margin-top: 40px;
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media screen and (max-width: $tab) {
    margin-top: 30px;
  }

  &.disabled {
    pointer-events: none;
  }

  &__btn {
    position: relative;
    padding: 24px 0 25px;
    width: 100%;
    text-align: center;

    // .icon {
    //   top: 5px;
    // }

    input {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
  }

  &__cover {
    background-color: $green;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 10;
    font-size: 18px;
    line-height: em(26px, 18px);
    letter-spacing: em(.2px, 18px);
    font-weight: 400;
    color: $white;
    opacity: 1;
    

    .icon {
      margin-right: 15px;
      path {
        fill: $white;
      }
    }
  }
}

.input-link {
  position: absolute;
  top: 11px;
  right: 0px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: em(.2px, 12px);
  cursor: pointer;
}

.remind-pass {

  @media screen and (max-width: $tab) {
    min-height: 250px;
  }
  
  .form_submit {

    .form_submit__btn {
      @media screen and (max-width: $tab) {
        position: relative;
        bottom: 0;
      }
    }
  }
}

form.disabled {
  opacity: .9;
  pointer-events: none;
}
